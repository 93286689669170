import createFetcher from "../common/axiosInstance";
import loadable from "@loadable/component";
import Loader from "@componentsShared/Loader/Loader";
import url from "url";

const fallback = {
    fallback: <Loader />,
};
export const TestsIndex = loadable(() => import("../testPages"), fallback);
export const TestStatusCode = loadable(() => import("../testPages/test-status-code"), fallback);
export const TestSSRStatusCode = loadable(() => import("../testPages/test-status-code-ssr"), fallback);
export const TestIsMobile = loadable(() => import("../testPages/test-is-mobile"), fallback);

const testRoutes = () => {
    if (__IS_PROD_ENV__) {
        return [];
    }
    return [
        {
            path: [
                "/tests",
            ],
            component: TestsIndex,
            name: "Test Index",
            exact: true,
        },
        {
            path: [
                "/tests/test-status-code",
            ],
            component: TestStatusCode,
            name: "Test Status Code",
            exact: true,
        },
        {
            path: [
                "/tests/test-is-mobile",
            ],
            component: TestIsMobile,
            name: "Test Is Mobile",
            exact: true,
        },
        {
            path: [
                "/tests/test-status-code-ssr",
                "/tests/test-status-code-ssr/400",
                "/tests/test-status-code-ssr/500",
            ],
            component: TestSSRStatusCode,
            getInitialData: ({req}) => {
                const code = req?.path.split('/').pop();
                if (isNaN(code)) { // when code === index then return 200
                    return Promise.resolve("continue test 200");
                }
                return createFetcher(req).get(`/site/test?code=${code}`);
            },
            name: "Test Status Code SSR",
            exact: false,
        },
    ]
}
export default testRoutes;
